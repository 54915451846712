import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../components/global/seo';
import Layout from '../components/global/layout/Layout';
import InfoBox from '../components/global/info-box/InfoBox';
import FeaturedCustomers from '../components/home-page/featured-customers/FeaturedCustomers';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import CTA from '../components/global/CTA/CTA';
import VerticalSlider from '../components/global/ImageSlider/VerticalSlider';
import { Container } from '../components/global/customers/styles';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import Snippet from '../components/global/snippet/Snippet';
import { sliderProps } from '../pages/bot-builder';
import * as S from '../components/demand-generation/styles';
import { SectionTitle } from '../components/bot-builder-page/styles';
import { ImageHolder } from '../components/home-page/about-chatbots/styles';
import {
	SliderWrapper,
	SliderContainer,
} from '../components/customer-support/styles';
import { SectionWrapper } from '../components/mid-market/styles';
import { HeroWrapper } from '../components/small-business/styles';
const MidMarket = ({ data }) => {
	const {
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoNoIndex,
		seoJsonSchema,
		section1Image,
		section1Button,
		section2Title,
		section2Images,
		section3Title,
		section4Image,
		section4Images,
		ctaTitle,
	} = data.contentfulMidMarket;
	return (
		<>
			<Layout>
				<SEO
					title={seoTitle}
					description={seoDescription}
					image={seoImage}
					article={seoArticle}
					noIndex={seoNoIndex}
					seoJsonSchema={seoJsonSchema}
				/>
				<WrapperOne>
					<HeroWrapper>
						<InfoBox
							image={section1Image}
							title={section1Image.title}
							subtitle={section1Image.description}
							buttonText={section1Button?.linkText}
							buttonLink={section1Button?.link}
							subHeading
							isHeroHeader
						/>
					</HeroWrapper>
				</WrapperOne>
				<SliderContainer>
					<SectionTitle className="font-black-54">{section2Title}</SectionTitle>
					<SliderWrapper>
						<VerticalSlider data={section2Images} {...sliderProps} />
					</SliderWrapper>
				</SliderContainer>
				<FeaturedCustomers
					section10Title={section3Title}
					customers={data.allContentfulCustomers.nodes}
				/>
				<Container>
					<SectionTextBox
						title={section4Image.title}
						subtitle={section4Image.description}
						largeHeading="true"
					/>
					<Img
						fluid={section4Image.fluid}
						alt={section4Image.title}
						style={{ maxWidth: '927px', margin: '0 auto', width: '100%' }}
					/>
					<SectionWrapper>
						<ImageHolder>
							{section4Images.map(info => (
								<Snippet
									url={info.file.url}
									title={info.title}
									subtitle={info.description}
								/>
							))}
						</ImageHolder>
					</SectionWrapper>
				</Container>
				<S.CTAWrapper>
					<Container>
						<CTA title={ctaTitle} />
					</Container>
				</S.CTAWrapper>
			</Layout>
		</>
	);
};

export const query = graphql`
	query($relatedCustomerSlug: [String]) {
		contentfulMidMarket {
			seoTitle
			seoDescription
			seoArticle
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoNoIndex
			seoJsonSchema {
				internal {
					content
				}
			}
			section1Image {
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
				title
				fluid(maxWidth: 1027, maxHeight: 687) {
					...GatsbyContentfulFluid
				}
				description
			}
			section1Button {
				link
				linkText
			}
			section2Title
			section2Images {
				title
				description
				fluid(maxHeight: 500, maxWidth: 747) {
					...GatsbyContentfulFluid
				}
			}
			section3Title
			section4Image {
				fluid {
					...GatsbyContentfulFluid
				}
				title
				description
			}
			section4Images {
				file {
					url
				}
				title
				description
			}
			ctaTitle
		}
		allContentfulCustomers(
			filter: { slug: { in: $relatedCustomerSlug } }
			sort: { order: ASC, fields: id }
		) {
			nodes {
				title
				slug
				cardImage {
					fluid(maxWidth: 234, maxHeight: 234) {
						...GatsbyContentfulFluid
					}
					title
				}
				cardLogo {
					fixed(width: 200) {
						...GatsbyContentfulFixed
					}
					title
				}
			}
		}
	}
`;

export default MidMarket;
