import styled from 'styled-components';
import { mediaQuery } from '../../../utils/helpers';

export const TestimonialWrapper = styled.div`
	margin-top: 100px;
	margin-bottom: 60px;
`;

export const SliderSectionWrapper = styled.div`
	padding-top: 100px;
	${mediaQuery.mobileXL`padding-top: 5px; margin-bottom: -40px;`}
	margin-bottom: 11px;
`;

export const HeroWrapper = styled.div`
	img {
		object-fit: cover !important;
	}
	.gatsby-image-wrapper {
		img {
			object-fit: cover !important;
		}
	}
`;
